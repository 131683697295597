import gql from 'graphql-tag';

export const getVisitSummaryLabVitalsResultDeprecated = gql`
    query VisitSummaryLabVitalsResult($id: Int!) {
        labVitalsResult(id: $id) {
            date
            category
        }
    }
`;

export const getVisitSummaryLabVitalsResult = gql`
    query VisitSummaryLabVitalsResult($id: Int!, $visitId: Int) {
        labVitalsResult(id: $id, visitId: $visitId) {
            date
            category
        }
    }
`;

export const getVisitSummaryDocumentedConcept = gql`
    query VisitSummaryDocumentedConcept($id: ID!) {
        documentedConcept(id: $id) {
            documentId
            medicalConceptId
        }
    }
`;

export const getTimelineDocument = gql`
    query TimelineDocument($id: Int, $visitId: Int, $documentedConceptId: ID, $searchText: String, $filter: DocumentFilter) {
        document(id: $id, visitId: $visitId, documentedConceptId: $documentedConceptId, searchText: $searchText, filter: $filter) {
            body
            author {
                firstName
                lastName
            }
            date
            title
            id
        }
    }
`;

export const getTimelineEvents = gql`
    query VisitTimelineEvents($visitId: Int!) {
        visitTimelineEvents(visitId: $visitId) {
            items {
                id
                type
                header
                subHeader
                timestamp
                groupedByDate
                groupedByType
            }
        }
    }
`;

export const getRelatedDocumentedConcepts = gql`
    query RelatedDocumentedConcepts($medicalConceptId: ID!) {
        relatedDocumentedConcepts(medicalConceptId: $medicalConceptId) {
            items {
                id
                documentId
                medicalConceptId
            }
        }
    }
`;

export const getMetaDataForDocuments = gql`
    query MetaDataForDocuments($filter: DocumentsFilter!) {
        documents(filter: $filter) {
            items {
                id
                author {
                    firstName
                    lastName
                }
                date
                title
            }
        }
    }
`;

export const getMetaDataForTimelineDocuments = gql`
    query MetaDataForDocuments($filter: TimelineDocumentsFilter!) {
        timelineDocuments(filter: $filter) {
            items {
                id
                cdiAuthor
                date
                title
            }
        }
    }
`;

export const getMedicationDetailViewMedicationAdministration = gql`
    query MedicationDetailMedicationAdministration($id: Int!) {
        medicationAdministration(id: $id) {
            id
            name
            amount
            units
            date
            medicationOrder {
                id
                name
                route
                amount
                startDate
                endDate
                orderDate
                orderedBy
                interval
            }
        }
    }
`;

export const getMedicationDetailViewMedicationOrder = gql`
    query MedicationDetailMedicationOrder($id: Int!) {
        medicationOrder(id: $id) {
            id
            name
            route
            amount
            startDate
            endDate
            orderDate
            orderedBy
            interval
            medicationAdministrations {
                items {
                    id
                    name
                    amount
                    units
                    date
                }
            }
        }
    }
`;

export const getMedicationDetailViewMedicationOrders = gql`
    query MedicationDetailMedicationOrder($filter: MedicationOrdersFilter!) {
        medicationOrders(filter: $filter) {
            items {
                id
                name
                route
                amount
                startDate
                endDate
                orderDate
                orderedBy
                interval
                medicationAdministrations {
                    items {
                        id
                        name
                        amount
                        units
                        date
                    }
                }
            }
        }
    }
`;

export const getLabVitalsResultsTable = gql`
    query LabVitalsResultsTable($category: String!, $visitId: Int!, $filter: LabVitalsResultsFilter) {
        labVitalsResultsTable(category: $category, visitId: $visitId, filter: $filter) {
            columns
            rows {
                name
                results {
                    id
                    value
                    abnormalFlags
                    referenceRange
                    status
                    code
                }
            }
        }
    }
`;

export const getLabVitalsResultsTableDeprecated = gql`
    query LabVitalsResultsTable($category: String!, $visitId: Int!) {
        labVitalsResultsTable(category: $category, visitId: $visitId) {
            columns
            rows {
                name
                results {
                    id
                    value
                    abnormalFlags
                    referenceRange
                    status
                }
            }
        }
    }
`;

export const getTimelineLabVitalsResult = gql`
    query TimelineLabVitalsResult($visitId: Int!, $category: String!, $date: String!) {
        labVitalsResults(visitId: $visitId, category: $category, date: $date) {
            id
            name
            date
            value
            units
            referenceRange
            status
            abnormalFlags
            code
        }
    }
`;

export const getTimelineLabVitalsResultDeprecated = gql`
    query TimelineLabVitalsResult($visitId: Int!, $category: String!, $date: String!) {
        labVitalsResults(visitId: $visitId, category: $category, date: $date) {
            id
            name
            date
            value
            units
            referenceRange
            status
            abnormalFlags
        }
    }
`;

export const getVisitConditions = gql`
    query VisitConditions($visitId: Int!) {
        visitConditions(visitId: $visitId) {
            items {
                id
                name
                evidence {
                    confidence
                    levels {
                        high
                        medium
                        low
                    }
                    history {
                        confidence
                        scoreDate
                    }
                }
                documentation {
                    confidence
                    levels {
                        high
                        medium
                        low
                    }
                }
            }
        }
    }
`;

export const getVisitConditionsLengthOfTreatment = gql`
    query VisitConditionsLengthOfTreatment($visitId: Int!) {
        visitConditionLengthOfTreatment(visitId: $visitId) {
            visitConditionId
            expectedTreatmentMin
            expectedTreatmentMax
            observedTreatmentLength
            startDate
            endDate
        }
    }
`;

export const getVisitConditionSupportingData = gql`
    query VisitConditionSupportingData($id: Int!) {
        visitCondition(id: $id) {
            supportingData {
                labVitalsResultsTable {
                    columns
                    rows {
                        name
                        results {
                            id
                            value
                            abnormalFlags
                            referenceRange
                            status
                        }
                    }
                }
                medicationAdministrations {
                    id
                    name
                    amount
                    units
                    date
                    medicationOrder {
                        id
                        name
                        route
                        amount
                        startDate
                        endDate
                        orderDate
                        orderedBy
                        interval
                    }
                }
                medicationOrders {
                    id
                    name
                    route
                    amount
                    startDate
                    endDate
                    orderDate
                    orderedBy
                    interval
                    medicationAdministrations {
                        items {
                            id
                            name
                            amount
                            units
                            date
                        }
                    }
                }
                serviceOrders {
                    title
                    mostRecentOrderDate
                    documents {
                        id
                        title
                        author {
                            firstName
                            lastName
                        }
                        date
                    }
                }
                mentions {
                    medicalConceptId
                    phrase
                    context
                    documents {
                        id
                        title
                        author {
                            firstName
                            lastName
                        }
                        date
                    }
                }
            }
        }
    }
`;

export const getClinicalDocuments = gql`
    query ClinicalDocuments($filter: ClinicalDocumentsFilter!) {
        clinicalDocuments(filter: $filter) {
            id
            isFromPayerPortal
            creator {
                firstName
                lastName
            }
            createdDate
            fax {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
                faxNumber
            }
            payerPortal {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
            }
        }
    }
`;

export const getClinicalDocument = gql`
    query ClinicalDocument($filter: ClinicalDocumentFilter!) {
        clinicalDocument(filter: $filter) {
            status
            creator {
                firstName
                lastName
            }
            createdDate
            hash
            id
            pdf
            fax {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
                faxNumber
                pdf
            }
            faxNumberId
            isReadyToSend
            isFromPayerPortal
            payerPortal {
                createdDate
                creator {
                    firstName
                    lastName
                }
                status
            }
        }
    }
`;

export const getClinicalDocumentMicrobiologyResults = gql`
    query ClinicalDocumentMicrobiologyResults($filter: ClinicalDocumentFilter!) {
        clinicalDocumentMicrobiologyResults(filter: $filter) {
            id
            name
            date
            orderedBy
        }
    }
`;

export const getClinicalDocumentMedicationOrders = gql`
    query ClinicalDocumentMedicationOrders($filter: ClinicalDocumentFilter!) {
        clinicalDocumentMedicationOrders(filter: $filter) {
            items {
                id
                name
                route
                amount
                startDate
                endDate
            }
        }
    }
`;

export const getClinicalDocumentMedicationAdministrations = gql`
    query ClinicalDocumentMedicationAdministrations($filter: ClinicalDocumentFilter!) {
        clinicalDocumentMedicationAdministrations(filter: $filter) {
            items {
                id
                name
                amount
                units
                date
                medicationOrder {
                    orderedBy
                    interval
                }
            }
        }
    }
`;

export const getClinicalDocumentLabVitalsResults = gql`
    query ClinicalDocumentLabVitalsResults($filter: ClinicalDocumentFilter!) {
        clinicalDocumentLabVitalsResultsTable(filter: $filter) {
            columns
            rows {
                name
                results {
                    id
                    value
                    abnormalFlags
                    referenceRange
                    status
                }
            }
        }
    }
`;

export const getClinicalDocumentDocuments = gql`
    query ClinicalDocumentDocuments($filter: ClinicalDocumentFilter!) {
        clinicalDocumentDocuments(filter: $filter) {
            ... on Document {
                __typename
                author {
                    firstName
                    lastName
                }
                date
                id
                title
            }
            ... on DocumentQuote {
                __typename
                document {
                    author {
                        firstName
                        lastName
                    }
                    date
                    id
                    title
                }
                documentId
                endRange
                quote
                startRange
            }
        }
    }
`;

export const getClinicalDocumentDraft = gql`
    query ClinicalDocumentDraft($filter: ClinicalDocumentDraftFilter!) {
        clinicalDocumentDraft(filter: $filter) {
            id
            note
            faxNumberId
            status
            isReadyToSend
        }
    }
`;

export const getClinicalDocumentDraftWithPDF = gql`
    query ClinicalDocumentDraft($filter: ClinicalDocumentDraftFilter!) {
        clinicalDocumentDraft(filter: $filter) {
            id
            note
            pdf
            hash
        }
    }
`;

export const getPayers = gql`
    query Payers($filter: PayersFilter) {
        payers(filter: $filter) {
            name
            code
            division
            faxNumbers {
                id
                faxNumber
                order
                type
            }
            rule {
                minObservationHours
                maxObservationHours
                clinicalsInitialHours
                clinicalsOngoingHours
                explicitAuthorizations
                reviewIntervalHours
                postDischargeClinicalsHours
            }
        }
    }
`;

export const getScopedHospitalServices = gql`
    query HospitalServices($includeOutOfScope: Boolean) {
        hospitalServices(includeOutOfScope: $includeOutOfScope) {
            name
            division
            code
            inScope
        }
    }
`;

export const getScopedPayers = gql`
    query Payers($filter: PayersFilter) {
        payers(filter: $filter) {
            name
            division
            code
            inScope
        }
    }
`;

export const getScopedFacilities = gql`
    query Facilities($includeOutOfScope: Boolean) {
        facilities(includeOutOfScope: $includeOutOfScope) {
            name
            division
            code
            locations(includeOutOfScope: $includeOutOfScope) {
                name
                division
                code
                inScope
            }
            inScope
        }
    }
`;

export const getAvoidableDays = gql`
    query AvoidableDays($filter: AvoidableDaysFilter!) {
        avoidableDays(filter: $filter) {
            id
            avoidableDate
            category
            reason
        }
    }
`;

export const getUserPreferences = gql`
    query UserPreferences {
        userPreferences {
            worklistFilters {
                fromAdmitDate
                toAdmitDate
                admitStatusCodes
                predictedAdmitStatusCodes
                facilityCodes
                locationCodes
                hospitalServiceCodes
                payerCodes
                payerCategoryCodes
                umNurseOwnerIds
                losExceedsGmlos
                activeDenials
                failedToSend
                readyToSend
                divisionCodes
                isNotReviewed
            }
        }
    }
`;

export const getGenAiTermsAcceptance = gql`
    query UserPreferences {
        userPreferences {
            acceptedGenAiTerms
        }
    }
`;

export const getWorklistTabs = gql`
    query WorklistTabs($role: String!, $filters: VisitFiltersInput!) {
        worklistTabs(role: $role, filters: $filters) {
            name
            count
        }
    }
`;

export const getVisitPayerRules = gql`
    query VisitPayerRules($filter: VisitFiltersInput!) {
        visitPayerRules(filter: $filter) {
            clinicalsAreDue
            id
        }
    }
`;

export const getVisitNextReviewDate = gql`
    query VisitNextReviewDate($id: Int!) {
        visit(id: $id) {
            nextReviewDate
        }
    }
`;
export const getPreviousVisit = gql`
    query PreviousVisit($id: Int!) {
        visit(id: $id) {
            previousVisit {
                id
                admitDate
                dischargeDate
            }
        }
    }
`;

export const getAnalytics = gql`
    query Analytics {
        analyticsItems {
            dashboards {
                title
                id
            }
            explores {
                title
                id
            }
            reports {
                title
                id
            }
        }
    }
`;

export const getExploreEmbedUrl = gql`
    query GetExploreEmbedUrl($id: String!) {
        getExploreEmbedUrl(exploreId: $id) {
            embedUrl
        }
    }
`;
export const getReportEmbedUrl = gql`
    query GetReportEmbedUrl($id: String!) {
        getReportEmbedUrl(lookId: $id) {
            embedUrl
        }
    }
`;
export const getDashboardEmbedUrl = gql`
    query GetDashboardEmbedUrl($id: String!) {
        getDashboardEmbedUrl(dashboardId: $id) {
            embedUrl
        }
    }
`;

export const getWorklistFilters = gql`
    query WorklistFilters {
        admitStatuses {
            code
            name
        }
        predictedAdmitStatuses {
            code
            name
        }
        facilities {
            code
            name
            division
        }
        locations {
            code
            name
            facility {
                name
            }
        }
        payers {
            code
            name
        }
        payerCategories {
            code
            name
        }
        hospitalServices {
            code
            name
        }
        usersByRole(role: "UM_NURSE") {
            id
            firstName
            lastName
        }
    }
`;

export const getVisitComments = gql`
    query VisitComments($filter: VisitCommentsFilter!) {
        visitComments(filter: $filter) {
            id
            version
            creator {
                firstName
                lastName
            }
            createdDate
            modifiedDate
            comment
            headerComment
            escalatedTo {
                firstName
                lastName
            }
            reviewOutcome
            reviewRecommendedAdmitStatus
            originalComment {
                createdDate
            }
        }
    }
`;
export const getVisitCommentsState = gql`
    query VisitCommentsState($filter: VisitCommentsStateFilter!) {
        visitCommentsState(filter: $filter) {
            unreadCount
        }
    }
`;

export const getVisitForPatientCard = gql`
    query VisitForPatientCard($id: Int!) {
        visit(id: $id) {
            id
            encounterNo
            attending {
                firstName
                id
                lastName
            }
            payerAuthorization {
                authNumber
            }
            chiefComplaint
            latestCaseNote
            umNurseOwnerId
            umNurseOwner {
                firstName
                lastName
            }
            physicianAdvisorOwnerId
            physicianAdvisorOwner {
                firstName
                lastName
            }
            patient {
                firstName
                lastName
                mrn
            }
            secondaryPayer {
                code
                name
            }
            hospitalService {
                code
                name
            }
            admitStatusMetrics {
                daysInInpatient
                hoursInObservation
            }
        }
    }
`;

export const getUsersByRole = gql`
    query UsersByRole($role: String!) {
        usersByRole(role: $role) {
            id
            firstName
            lastName
        }
    }
`;

export const getVisitsBySearchText = gql`
    query VisitsBySearchText($searchText: String!) {
        visitsBySearchText(searchText: $searchText) {
            id
            encounterNo
            admitDate
            dischargeDate
            admitStatus

            patient {
                mrn
                firstName
                middleName
                lastName
            }
        }
    }
`;

export const getCurrentAdmitStatusPrediction = gql`
    query VisitCurrentAdmitStatusPrediction($id: Int!) {
        visit(id: $id) {
            currentAdmitStatusPrediction {
                dischargeConfidence
                inpatientConfidence
                observationConfidence
                admitConfidence
            }
        }
    }
`;

export const getVisitsByWorklistTab = gql`
    query VisitsByWorklistTab($worklistTabName: String!, $filters: VisitFiltersInput!, $offset: Int!, $limit: Int!) {
        visitsByWorklistTab(worklistTabName: $worklistTabName, filters: $filters, offset: $offset, limit: $limit) {
            id
            encounterNo
            admitDate
            lastInpatientAdmitDate
            lastObservationAdmitDate
            lastEmergencyAdmitDate
            chiefComplaint
            los
            predictedLos
            priorityScore
            admitStatus
            predictedAdmitStatus
            predictedAdmitStatusConfidence
            dischargeDate
            payerAuthorization {
                authStatus
                authNumber
                startDate
                endDate
            }
            lastReviewDate
            lastFaxRequestFailed
            latestCaseNote
            nextReviewDate
            umNurseOwnerId
            lastClinicalsSentDate
            inpatientOnly

            umNurseOwner {
                firstName
                lastName
            }
            physicianAdvisorOwnerId
            physicianAdvisorOwner {
                firstName
                lastName
            }
            patient {
                firstName
                middleName
                lastName
                gender
                age
                mrn
                dob
            }
            location {
                facility {
                    name
                }
                poc {
                    name
                }
                bed
                room
            }
            hospitalService {
                name
            }
            primaryPayer {
                name
            }
            secondaryPayer {
                name
            }
            attending {
                firstName
                lastName
            }
            lastReviewOutcome
            lastReviewType
            lastReviewer {
                firstName
                lastName
            }
            lastSecondLevelReview {
                recommendedAdmitStatus
            }
            latestUnresolvedDenial {
                denialReasonDescription
                denialStartDate
                denialEndDate
            }
            unresolvedDenialCount
        }
    }
`;

export const getHelpLinks = gql`
    query HelpLinks {
        helpLinks {
            url
            displayText
        }
    }
`;

export const getVisitSummary = gql`
    query VisitSummary($id: Int!, $filter: DateBoundsFilter!) {
        visit(id: $id) {
            summaries(filter: $filter) {
                type
                createdDate

                items {
                    id
                    name
                    description
                    type
                    referenceId
                    descriptionJson
                }
            }
        }
    }
`;

export const getPredictedAdmitStatusHistory = gql`
    query PredictedAdmitStatusHistory($id: Int!, $filter: DateBoundsFilter!) {
        admitStatusPredictionHistory(id: $id, filter: $filter) {
            datePredicted
            inpatientConfidence
            observationConfidence
            admitConfidence
        }
    }
`;

export const getTextGenServiceEndpoint = gql`
    query Config {
        config {
            textGenService {
                endpoint
            }
        }
    }
`;

export const getWorklistRefreshRate = gql`
    query Config {
        config {
            worklist {
                refreshRate
            }
        }
    }
`;

export const getFrontendVersion = gql`
    query Config {
        config {
            frontendVersion {
                version
                interval
            }
        }
    }
`;

export const getImportMapUrl = gql`
    query Config {
        config {
            importMap {
                url
            }
        }
    }
`;

export const getIpoProcedure = gql`
    query VisitIpoProcedure($id: Int!) {
        inpatientOnlyProcedures(id: $id) {
            name
        }
    }
`;

export const initializeInteract = gql`
    query Interact {
        initialize {
            sessionToken
            buildNumber
            baseUrl
        }
    }
`;

export const getVisitDenials = gql`
    query VisitDenials($filter: DenialsFilter!) {
        denials(filter: $filter) {
            id
            denialType
            dateReceived
            denialStartDate
            denialEndDate
            denialReasonDescription
            payerPhoneNumber
            creator {
                firstName
                lastName
            }
            status
            resolvedDate
            communicationMethod
            outcome
            overturnAmount
            escalationQueryId
            appealMethod
            appealLetter {
                id
            }
        }
    }
`;

export const getDenialReasons = gql`
    query denialReasons {
        denialReasons {
            id
            description
        }
    }
`;

export const getSavedClinicalDocuments = gql`
    query ClinicalDocuments($filter: ClinicalDocumentsFilter!) {
        clinicalDocuments(filter: $filter) {
            id
            creator {
                firstName
                lastName
            }
            createdDate
            isReadyToSend
        }
    }
`;
