
import { noAdultPredictedAdmitStatusDisclaimer, noChildPredictedAdmitStatusDisclaimer } from '@/components/shared/utils/model/Disclaimers';
import { ADULT_AGE } from '@/models/visit/visit-summary/VisitPatientAge.model';
import { useFeatureStore } from '@/stores/FeatureStore';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NoPredictionIcon',
    props: {
        patientAge: {
            type: Number,
            required: false,
            default: undefined,
            validator: (value) => value === undefined || value === null || typeof value === 'number',
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
    }),
    computed: {
        showPedsSpecificCopy(): boolean {
            if (this.isPedsPredictionsEnabled) {
                return false;
            }
            return this.effectivePatientAge < ADULT_AGE;
        },
        effectivePatientAge(): number {
            return this.patientAge === null || this.patientAge === undefined ? 18 : this.patientAge;
        },
        tooltipText(): string {
            return this.showPedsSpecificCopy ? noChildPredictedAdmitStatusDisclaimer : noAdultPredictedAdmitStatusDisclaimer;
        },
        iconName(): string {
            return this.showPedsSpecificCopy ? 'fa-light fa-hands-holding-child' : 'fa-light fa-hourglass-clock';
        },
        iconTestId(): string {
            return this.showPedsSpecificCopy ? 'no-prediction-icon-child' : 'no-prediction-icon-adult';
        },
        isPedsPredictionsEnabled(): boolean {
            return this.featureStore.isEnabled('ENABLE_PEDS_PREDICTIONS');
        },
    },
});
